import React from 'react'
import WorkCard from '../components/WorkCard'

export default function WorkCategory({category, categoryName, projects}) {
    const projectsCategory = []
    projects.forEach(edge => {
        if (edge.node.childMdx.frontmatter.category === category ) {
            projectsCategory.push(edge)
        }
    })
    return (
        <div className='work-category'>
                <div className="work-category-heading"><h3>{ categoryName }</h3></div>
                {
                    projectsCategory.map(project  => (
                        <WorkCard 
                            thumb={ project.node.childMdx.frontmatter.thumb } 
                            imageThumb={ project.node.childMdx.frontmatter.highlightImage.childImageSharp.gatsbyImageData}
                            title={ project.node.childMdx.frontmatter.title } 
                            description={ project.node.childMdx.frontmatter.description } 
                            featured={ project.node.childMdx.frontmatter.featured } 
                            slug={ project.node.name } 
                            key={ project.node.id } />
                    )) 
                }
        </div>
    )
}

