import React from "react"
import WorkCardContent from '../components/WorkCardContent'

const cardStyle = ['work-card', 'work-card-featured']

const WorkCard = ({ thumb, imageThumb, title, description, slug, featured }) => (
    <div className={cardStyle[featured]}>
        <a href={'/projects/' + slug}>
            <WorkCardContent videoThumb = { thumb } imageThumb = { imageThumb } />
            <div className="link-title">{ title }</div>
            <div className="link-description">{ description }</div>
        </a>
    </div>
)

export default WorkCard

