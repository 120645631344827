import React from 'react'
import VimeoPlayer from '../components/VimeoPlayer'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function WorkCardContent({ videoThumb, imageThumb}) {

    let content = <div></div>
    const image = getImage(imageThumb)

    if (videoThumb === 0) {
        
        content = <div className='work-image-content'><GatsbyImage image={ image } alt='' loading="lazy" /></div>
    }
    else {
        content = <div className='work-video-content'><div className='work-image-fallback'><GatsbyImage image={ image } alt='' loading="lazy" /></div><VimeoPlayer videoID={ videoThumb } showControls = '0'/></div>
    }
    
    return (
        <div>
            { content }
        </div>
    )
}
