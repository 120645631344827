import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import WorkCategory from '../components/WorkCategory'

const theme = 'bright' //Set the color theme here

export default function Works({ data }) {
    const projects = data.allFile.edges
    return (
        <Layout theme={ theme } currentPath = 'works'>

            <Helmet>
                <title>{'VOLNA / Works'}</title>
                <meta name="description" content="Selected works" />
                <link rel="canonical" href="https://volna-media.com/works" />
                <meta property="og:url" content="https://volna-media.com/works" />
                <meta property="og:title" content='VOLNA / Works' />
                <meta property="og:description" content="Selected works" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:creator" content="VOLNA" />
                <meta name="twitter:title" content='VOLNA / Works' />
                <meta name="twitter:description" content="Selected works" />
            </Helmet>

            <WorkCategory categoryName="Installations" category={ 0 } projects={projects}/>
            <WorkCategory categoryName="Scenography" category={ 1 } projects={projects}/>
            <WorkCategory categoryName="Videos" category={ 2 } projects={projects}/>
            <WorkCategory categoryName="Special projects" category={ 4 } projects={projects}/> 
            {/* <WorkCategory categoryName="Graphics" category={ 3 } projects={projects}/> */}
        </Layout>
    )
}

export const query = graphql`
query ProjectsList {
    allFile(
      filter: {ext: {eq: ".mdx"}}
      sort: {fields: childrenMdx___frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          name
          id
          childMdx {
            frontmatter {
              category
              description
              featured
              title
              thumb
              highlightImage {
                childImageSharp {
                  gatsbyImageData (
                    width: 3840
                    aspectRatio: 1.33333
                    transformOptions: {fit: COVER, cropFocus: CENTER}
                    )
                }
                }
            }
          }
        }
      }
    }
  }
`